var render = function render(){
  var _vm$automation$lastCo, _vm$automation$lastCo2, _vm$automation$lastCo3, _vm$automation$lastCo4, _vm$automation$lastCo5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rounded-md p-4 bg-lightest"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-lightbulb",
      "label": "Active"
    }
  }, [_vm.automation.active ? _c('i', {
    staticClass: "fa fa-check-circle text-success"
  }) : _c('i', {
    staticClass: "fa fa-times-circle text-danger"
  })]), _c('TextListItem', {
    staticClass: "text-capitalize mb-2",
    attrs: {
      "icon": "fa-tag",
      "label": "Category"
    }
  }, [_vm._v(_vm._s(_vm.automation.category))]), _c('TextListItem', {
    staticClass: "text-capitalize mb-2",
    attrs: {
      "icon": "fa-list",
      "label": "Source"
    }
  }, [_vm._v(_vm._s(_vm.automation.source))]), _c('TextListItem', {
    staticClass: "text-capitalize mb-2",
    attrs: {
      "icon": "fa-conveyor-belt",
      "label": "Service"
    }
  }, [_vm._v(_vm._s(_vm.automation.service))]), _c('TextListItem', {
    staticClass: "text-capitalize mb-2",
    attrs: {
      "icon": "fa-timer",
      "label": "Frequency"
    }
  }, [_vm._v(_vm._s(_vm.automation.frequency))])], 1), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('label', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.automation.service) + " specific params")]), _vm.$auth.isAdmin ? _c('button', {
    staticClass: "btn btn-primary btn-sm mb-2",
    attrs: {
      "disabled": _vm.disableRunManually
    },
    on: {
      "click": _vm.onClickRunManually
    }
  }, [_vm._v(" Run Manually ")]) : _vm._e()]), _c('div', {
    staticClass: "bg-white rounded-md p-3"
  }, [_c('pre', [_vm._v(_vm._s(_vm.automation.data))])])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('label', [_vm._v("Last Collection Information")]), _c('TextListItem', {
    staticClass: "mb-2",
    attrs: {
      "icon": "fa-clock",
      "label": "Timestamp"
    }
  }, [_vm._v(_vm._s(_vm._f("date")((_vm$automation$lastCo = _vm.automation.lastCollection) === null || _vm$automation$lastCo === void 0 ? void 0 : _vm$automation$lastCo.collectedAt, 'Do MMM YYYY HH:mm:ss')))]), _c('TextListItem', {
    staticClass: "text-capitalize mb-2",
    attrs: {
      "icon": "fa-tag",
      "label": "Status"
    }
  }, [_vm._v(_vm._s((_vm$automation$lastCo2 = _vm.automation.lastCollection) === null || _vm$automation$lastCo2 === void 0 ? void 0 : _vm$automation$lastCo2.status))]), _c('TextListItem', {
    staticClass: "text-capitalize mb-2",
    attrs: {
      "icon": "fa-circle-exclamation",
      "label": "Error"
    }
  }, [_vm._v(_vm._s((_vm$automation$lastCo3 = _vm.automation.lastCollection) === null || _vm$automation$lastCo3 === void 0 ? void 0 : _vm$automation$lastCo3.error))])], 1), _c('div', [_c('label', [_vm._v("Last Collected Data Sample")]), Object.keys(((_vm$automation$lastCo4 = _vm.automation.lastCollection) === null || _vm$automation$lastCo4 === void 0 ? void 0 : _vm$automation$lastCo4.data) || {}).length > 0 ? _c('div', {
    staticClass: "bg-white rounded-md p-3"
  }, [_c('pre', [_vm._v(_vm._s((_vm$automation$lastCo5 = _vm.automation.lastCollection) === null || _vm$automation$lastCo5 === void 0 ? void 0 : _vm$automation$lastCo5.data))])]) : _c('div', {
    staticClass: "alert alert-warning mb-0"
  }, [_vm._v("No Data")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }