<template>
  <SpinnerLogo v-if="accountsLoading.getAccount" />

  <div v-else>
    <div class="row mb-4">
      <div class="col-xl-2 col-lg-3 col-md-4 col-4">
        <VerticalPills v-if="$route.name !== 'automation-create'" :items="categories" item-key="id">
          <template #item="{ item }">
            <a href="#" class="nav-link" :class="{ active: selectedCategoryId === item.id }" @click.prevent="onSelectCategory(item)">
              <div class="mb-0"><i class="fa fa-fw mr-1 text-primary" :class="`${item.icon}`"></i> {{ item.title }}</div>
            </a>
          </template>
        </VerticalPills>
      </div>

      <div v-if="loading" class="col">
        <SpinnerLogo />
      </div>

      <div v-else-if="$route.name === 'automation-create'" class="col-xl-5">
        <SectionTitle icon="fa-plus">
          <div>Create New Automation</div>
        </SectionTitle>
        <AutomationForm v-model="form" form-type="create" />

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" @click="onClickSave">Save</button>
        </div>
      </div>

      <div v-else-if="selectedCategoryId === 'overview'" class="col-xl-5">
        <SectionTitle icon="fa-file-invoice"
          >Overview
          <div class="small text-muted">{{ automation.description }}</div></SectionTitle
        >

        <AutomationInfo :automation="automation" />
      </div>

      <div v-else-if="selectedCategoryId === 'manage'" class="col-xl-5">
        <SectionTitle icon="fa-cog"
          >Manage
          <div class="small text-muted">{{ automation.description }}</div></SectionTitle
        >
        <AutomationForm v-model="form" form-type="edit" />

        <div class="d-flex justify-content-end">
          <button v-if="$route.name === 'automation-manage'" class="btn btn-danger mr-4" @click="deleteModal = true">Delete</button>

          <button class="btn btn-primary" @click="onClickSave">Save</button>
        </div>
      </div>

      <div v-else-if="selectedCategoryId === 'logs'" class="col">
        <SectionTitle icon="fa-list"
          >Logs
          <div class="small text-muted">{{ automation.description }}</div></SectionTitle
        >

        <FormItem
          id="logs-date-range"
          v-model="logsDateFilter"
          class="mb-3"
          alt
          date-format="DD MMM YYYY"
          type="dateRangePicker"
          @input="onDateRangeUpdate"
        />

        <div v-if="automation.logs?.length > 0">
          <div v-for="(logs, batchId) in groupedLogs" :key="batchId" class="mb-4">
            <p class="font-w600 mb-1">{{ logs[0].timestamp | date('Do MMMM YYYY [-] HH:mm:ss') }}</p>
            <pre
              class="pre--dark rounded-md p-4"
            ><div v-for="log in logs" :key="log._id">[{{ log.timestamp | date('DD/MM/YYYY HH:mm:ss')  }}] {{ log.message }}</div></pre>
          </div>
        </div>
        <div v-else class="alert alert-warning mb-0">No Data</div>
      </div>
    </div>
    <ConfirmModal :open="!!deleteModal" title="Delete Automation" @close="deleteModal = false" @submit="onClickRemoveAutomation" />
  </div>
</template>
<script>
import { setDefaultForm } from '@/lib/helpers';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import AutomationForm from '@/components/forms/AutomationForm';
import AutomationInfo from '@/components/automation/AutomationInfo';
import ConfirmModal from '@/components/ConfirmModal';
import FormItem from '@/components/FormItem';
import SpinnerLogo from '@/components/SpinnerLogo';
import SectionTitle from '@/components/base/SectionTitle';
import VerticalPills from '@/components/base/VerticalPills';

const defaultForm = () => ({
  frequency: 'never',
  description: '',
  data: {},
  sourceData: {},
  active: false,
  source: 's3',
  service: null,
  category: 'company',
  accountIds: [],
  assetIds: []
});

export default {
  name: 'AutomationManage',
  components: {
    AutomationInfo,
    AutomationForm,
    ConfirmModal,
    FormItem,
    SpinnerLogo,
    SectionTitle,
    VerticalPills
  },
  data() {
    return {
      deleteModal: false,
      loading: true,
      form: defaultForm(),
      categories: [
        { id: 'overview', title: 'Overview', icon: 'fa-file-invoice' },
        { id: 'manage', title: 'Manage', icon: 'fa-gear' },
        { id: 'logs', title: 'Logs', icon: 'fa-list' }
      ],
      selectedCategoryId: 'overview',
      logsDateFilter: [moment().subtract(1, 'day').toDate(), moment().toDate()]
    };
  },
  computed: {
    ...mapGetters({
      accountsLoading: 'account/loadingAction',
      automation: 'automation/automation',
      accounts: 'account/accounts'
    }),
    isEdit() {
      return this.$route.name === 'automation-manage';
    },
    account() {
      if (this.form.category !== 'accounts') return null;
      return this.accounts.find(a => a._id === this.form.accountId);
    },
    groupedLogs() {
      const logs = [...this.automation.logs];

      logs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      const sortedLogs = logs.filter(log => {
        const logDate = new Date(log.timestamp);
        return logDate >= this.logsDateFilter[0] && logDate <= this.logsDateFilter[1];
      });

      return sortedLogs.reduce((acc, log) => {
        if (!acc[log.batchId]) acc[log.batchId] = [];
        acc[log.batchId].push(log);
        acc[log.batchId].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        return acc;
      }, {});
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.getAutomation({ id: this.$route.params.id });
    }

    if (this.$route.params.id) {
      this.form = setDefaultForm(defaultForm(), this.automation);
    }

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAccount: 'account/get',
      createAutomation: 'automation/create',
      removeAutomation: 'automation/remove',
      updateAutomation: 'automation/update',
      getAutomation: 'automation/get',
      getAccounts: 'account/list'
    }),
    async refresh() {
      this.loading = true;

      if (this.$route.params.id) {
        await this.getAutomation({ id: this.$route.params.id });
        this.form = setDefaultForm(defaultForm(), this.automation);
      } else {
        this.form = setDefaultForm(defaultForm());
      }

      this.loading = false;
    },
    async onClickSave() {
      this.loading = true;

      if (this.isEdit) {
        await this.updateSelectedAutomation();
      } else {
        await this.createNewAutomation();
      }
    },
    async createNewAutomation() {
      const automation = await this.createAutomation({ data: this.form });

      if (!automation._id) {
        this.loading = false;
        return this.$toast.error('Failed to create automation');
      }

      this.loading = false;

      this.$toasted.success('Automation added successfully.');
    },
    async updateSelectedAutomation() {
      await this.updateAutomation({ id: this.$route.params.id, data: this.form });

      this.$toasted.success('Automation updated successfully.');

      this.refresh();
    },
    async onClickRemoveAutomation() {
      this.loading = true;

      await this.removeAutomation({ id: this.$route.params.id });
      this.$toasted.success('Automation deleted successfully.');

      this.$router.push({
        name: 'automation-list'
      });

      this.loading = false;
    },
    onSelectCategory(item) {
      this.selectedCategoryId = item.id;

      this.$router.replace({ query: { category: item.id } });
    },
    onDateRangeUpdate(dateRange) {
      this.logsDateFilter = dateRange;

      this.$router.replace({ query: { logsDateFilter: dateRange.map(d => d.toISOString()) } });
    }
  }
};
</script>
