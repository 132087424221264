var render = function render(){
  var _vm$automation$logs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.accountsLoading.getAccount ? _c('SpinnerLogo') : _c('div', [_c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-xl-2 col-lg-3 col-md-4 col-4"
  }, [_vm.$route.name !== 'automation-create' ? _c('VerticalPills', {
    attrs: {
      "items": _vm.categories,
      "item-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('a', {
          staticClass: "nav-link",
          class: {
            active: _vm.selectedCategoryId === item.id
          },
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.onSelectCategory(item);
            }
          }
        }, [_c('div', {
          staticClass: "mb-0"
        }, [_c('i', {
          staticClass: "fa fa-fw mr-1 text-primary",
          class: `${item.icon}`
        }), _vm._v(" " + _vm._s(item.title))])])];
      }
    }], null, false, 175531760)
  }) : _vm._e()], 1), _vm.loading ? _c('div', {
    staticClass: "col"
  }, [_c('SpinnerLogo')], 1) : _vm.$route.name === 'automation-create' ? _c('div', {
    staticClass: "col-xl-5"
  }, [_c('SectionTitle', {
    attrs: {
      "icon": "fa-plus"
    }
  }, [_c('div', [_vm._v("Create New Automation")])]), _c('AutomationForm', {
    attrs: {
      "form-type": "create"
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onClickSave
    }
  }, [_vm._v("Save")])])], 1) : _vm.selectedCategoryId === 'overview' ? _c('div', {
    staticClass: "col-xl-5"
  }, [_c('SectionTitle', {
    attrs: {
      "icon": "fa-file-invoice"
    }
  }, [_vm._v("Overview "), _c('div', {
    staticClass: "small text-muted"
  }, [_vm._v(_vm._s(_vm.automation.description))])]), _c('AutomationInfo', {
    attrs: {
      "automation": _vm.automation
    }
  })], 1) : _vm.selectedCategoryId === 'manage' ? _c('div', {
    staticClass: "col-xl-5"
  }, [_c('SectionTitle', {
    attrs: {
      "icon": "fa-cog"
    }
  }, [_vm._v("Manage "), _c('div', {
    staticClass: "small text-muted"
  }, [_vm._v(_vm._s(_vm.automation.description))])]), _c('AutomationForm', {
    attrs: {
      "form-type": "edit"
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.$route.name === 'automation-manage' ? _c('button', {
    staticClass: "btn btn-danger mr-4",
    on: {
      "click": function ($event) {
        _vm.deleteModal = true;
      }
    }
  }, [_vm._v("Delete")]) : _vm._e(), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onClickSave
    }
  }, [_vm._v("Save")])])], 1) : _vm.selectedCategoryId === 'logs' ? _c('div', {
    staticClass: "col"
  }, [_c('SectionTitle', {
    attrs: {
      "icon": "fa-list"
    }
  }, [_vm._v("Logs "), _c('div', {
    staticClass: "small text-muted"
  }, [_vm._v(_vm._s(_vm.automation.description))])]), _c('FormItem', {
    staticClass: "mb-3",
    attrs: {
      "id": "logs-date-range",
      "alt": "",
      "date-format": "DD MMM YYYY",
      "type": "dateRangePicker"
    },
    on: {
      "input": _vm.onDateRangeUpdate
    },
    model: {
      value: _vm.logsDateFilter,
      callback: function ($$v) {
        _vm.logsDateFilter = $$v;
      },
      expression: "logsDateFilter"
    }
  }), ((_vm$automation$logs = _vm.automation.logs) === null || _vm$automation$logs === void 0 ? void 0 : _vm$automation$logs.length) > 0 ? _c('div', _vm._l(_vm.groupedLogs, function (logs, batchId) {
    return _c('div', {
      key: batchId,
      staticClass: "mb-4"
    }, [_c('p', {
      staticClass: "font-w600 mb-1"
    }, [_vm._v(_vm._s(_vm._f("date")(logs[0].timestamp, 'Do MMMM YYYY [-] HH:mm:ss')))]), _c('pre', {
      staticClass: "pre--dark rounded-md p-4"
    }, _vm._l(logs, function (log) {
      return _c('div', {
        key: log._id
      }, [_vm._v("[" + _vm._s(_vm._f("date")(log.timestamp, 'DD/MM/YYYY HH:mm:ss')) + "] " + _vm._s(log.message))]);
    }), 0)]);
  }), 0) : _c('div', {
    staticClass: "alert alert-warning mb-0"
  }, [_vm._v("No Data")])], 1) : _vm._e()]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.deleteModal,
      "title": "Delete Automation"
    },
    on: {
      "close": function ($event) {
        _vm.deleteModal = false;
      },
      "submit": _vm.onClickRemoveAutomation
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }